@import 'main';

$element-padding: 9px 20px 9px;
$element-padding-mobile: 9px 8px 9px 20px;

.nav-bar-search {
  @include max-width;

  display: flex;
  position: relative;
  border-radius: $border-radius-size;
  grid-area: search;
  justify-content: baseline;

  @include respond-to(mobile) {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 1%;
  }

  &__input {
    @include color-var(color, primary-text-color);
    @include color-var(background-color, field-color);

    border-radius: $border-radius-size 0 0 $border-radius-size;
    appearance: none;
    border: 0;
    caret-color: $red;
    display: block;
    flex-grow: 1;
    font-size: $text-input-size;
    font-weight: bold;
    margin: 0;
    outline: none;
    padding: $element-padding;
    letter-spacing: -0.03em;

    // &:focus {
    //   @include color-var(background-color, stroke-color);
    // }

    ::-ms-clear {
      display: none;
    }

    &::placeholder {
      @include color-var(color, minimal-text-color);
    }

    @include respond-to(mobile) {
      min-width: 0;
      border-radius: 0;
      padding: $element-padding-mobile;
    }
  }

  &__actions {
    @include color-var(background-color, field-color);

    border-radius: 0 $border-radius-size $border-radius-size 0;
    display: flex;
    min-width: 20px;
    align-items: center;
    gap: 10px;
    padding-right: 10px;

    @include respond-to(mobile) {
      border-radius: 0;
      min-width: auto;
      // display: none;
    }
  }

  // &__input:focus~&__actions {
  //   @include color-var(background-color, stroke-color);
  // }

  &__scheme {
    @include color-var(color, inverse-text-color);
    @include color-var(border-left-color, background-color);

    border-left-width: 4px;
    border-left-style: solid;
    height: 100%;
    align-items: center;
    appearance: none;
    border-radius: 3px;
    flex-shrink: 0;
    text-wrap: none;
    box-sizing: border-box;
    display: flex;
    font-size: 1rem;
    outline: 0;
    padding: 4px 4px 4px 15px;
    stroke: 0;
    font-weight: 800;
  }
}
